import fetcher from './fetcher';

const cmsClient = async (url, options = {}, redirectNotFound) => {
  const isPreview =
    options?.preview === true || options?.preview === 'true' || options?.preview === '1';

  const fullOptions = {
    ...(isPreview
      ? { cache: 'no-store' }
      : options?.onDemandRevalidation
        ? {}
        : { next: { revalidate: 120 } }),
    ...options,
  };
  const fullUrl = new URL(`${process.env.NEXT_PUBLIC_CMS_URL}/api${url}`);

  if (isPreview) {
    fullUrl.searchParams.append('preview', 1);
  }

  const secret = options?.secret;

  if (secret) {
    fullUrl.searchParams.append('secret', secret);
  }

  return await fetcher(fullUrl.toString(), fullOptions, redirectNotFound);
};
export default cmsClient;
